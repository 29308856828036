@import "~/node_modules/bootswatch/dist/united/bootstrap.min.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}
p {
  margin: 0;
}

/***********************************************************************/
.App {
  max-width: 100rem;
  margin: 0 auto;
}
/*********weather-from*************************************************/
.weather-form {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input {
  font-size: 1.6rem;
  padding: 1rem 1.4rem;
  max-width: 30rem;
}
.btn {
  padding: 1.4rem 1.6rem;
}

/***************************************************************************/
/***********weather-card***************************************************/
.card-container {
  border: 2px solid orange;
  margin-top: 2rem;
  padding: 4.8rem;
  max-width: 36rem;
  margin: 1.6rem auto 0 auto;
  border-radius: 0.6rem;
  box-shadow: 1.2rem 1.4rem 1.6rem rgba(0, 0, 0, 0.2);
  color: #333;
}
.city-name {
  font-size: 3.2rem;
  text-align: center;
  margin-bottom: 2rem;
}
.info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.text {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
  font-size: 1.6rem;
}
.text-temp {
  font-size: 3.2rem;
  font-weight: bolder;
  color: #333;
}
.weather-img {
  height: 8rem;
  width: 8rem;
}
.main,
.description {
  font-weight: bolder;
  color: #33333398;
  font-size: 1.4rem;
}

.typewriter {
  text-align: center;
  color: orange;
  margin-top: 10.8rem;
  font-weight: bolder;
  font-size: 4.8rem;
}
.btn-close {
  margin-left: 25rem;
  font-size: 1.6rem;
}
.error-msg {
  color: red;
  text-align: center;
  margin-top: 8rem;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8rem;
}
/*****************************************************************/
/**********responsive********************************************/

@media (max-width: 912px) {
}
@media (max-width: 870px) {
}

@media (max-width: 700px) {
}

@media (max-width: 600px) {
}

@media (max-width: 550px) {
  .typewriter {
    font-size: 3.6rem;
  }
}
@media (max-width: 415px) {
  .typewriter {
    font-size: 3rem;
  }
}
@media (max-width: 380px) {
  .typewriter {
    font-size: 2.8rem;
  }
  .input {
    font-size: 1.4rem;
    padding: 1rem 1.4rem;
    max-width: 25rem;
  }
  .btn {
    padding: 1.1rem 1.6rem;
    font-size: 1.2rem;
  }
  .card-container {
    max-width: 35rem;
  }
}
@media (max-width: 280px) {
  .typewriter {
    font-size: 2rem;
  }
  .input {
    font-size: 1.2rem;
    padding: 1rem 1.4rem;
    max-width: 20rem;
  }
  .btn {
    padding: 1.1rem 1.6rem;
    font-size: 1rem;
  }

  .card-container {
    max-width: 26rem;
  }
  .btn-close {
    margin-left: 17rem;
  }
}
